import {typesenseConfigForProfile} from "@buildwithflux/core";
import {SearchProvider} from "@buildwithflux/models";
import {useMemo} from "react";
import {SearchParams} from "typesense/lib/Typesense/Documents";
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import {create, type StoreApi, type UseBoundStore} from "zustand";

import {useFluxServices} from "../../../injection/hooks";
import type {TypesenseConnector} from "../../storage_engine/connectors/TypesenseConnector";

type TypesenseSearchClientState = {
    searchClient: TypesenseConnector["searchClient"];
    subscriptionHandle: () => void;
};

export type UseTypesenseSearchClientStore = UseBoundStore<StoreApi<TypesenseSearchClientState>>;

export const createTypesenseSearchClientStoreHook = (
    typesenseConnector: TypesenseConnector,
): UseTypesenseSearchClientStore =>
    create<TypesenseSearchClientState>()((set) => {
        const unsubHandle = typesenseConnector.subscribeToSearchClientChanges((client) => {
            set({searchClient: client});
        });

        return {
            searchClient: typesenseConnector.getCurrentSearchClient(),
            subscriptionHandle: unsubHandle,
        };
    });

export function useTypesenseSearchClient(): TypesenseConnector["searchClient"] {
    return useFluxServices().useTypesenseSearchClientStore((state) => state.searchClient);
}

export function useTypesenseInstantSearchClient(
    query_by: string,
    sort_by?: SearchParams["sort_by"],
): TypesenseInstantsearchAdapter["searchClient"] {
    const typesenseClient = useFluxServices().useTypesenseSearchClientStore((state) => state.searchClient);

    return useMemo(() => {
        return !typesenseClient
            ? undefined
            : new TypesenseInstantsearchAdapter({
                  server: {
                      ...typesenseClient.configuration,
                  },
                  additionalSearchParameters: {
                      query_by,
                      sort_by,
                      ...typesenseConfigForProfile,
                  },
              }).searchClient;
    }, [typesenseClient, query_by, sort_by]);
}

export function useSearchClientProvider(): SearchProvider {
    return "typesense";
}
