import {
    CameraMode,
    colors,
    CommentThreadAnchorType,
    documentIsPublished,
    EditorModes,
    ElementHelper,
    getAbsoluteDocumentUrl,
    getDesignatorPrefixFromProperties,
    getProcessed3dModelUrl,
    ICommentThreadData,
    ICommentThreadsMap,
    IDocumentNet,
    IRouteData,
    IRoutesMap,
    IUserData,
    IUserPresenceData,
    partPropertyCategories,
    PcbRule,
    ROUTE_BRANCH_POINT_PART_UID,
    ROUTE_BRANCH_POINT_TERMINAL_UID,
    UserPresenceStatus,
    vec2,
} from "@buildwithflux/core";
import {
    createDefaultAnonUserPermissions,
    DocumentPermissionsData,
    EnterpriseHandle,
    EnterpriseUid,
    IBaseDocumentData,
    IDocumentData,
    IElementData,
    IElementsMap,
    IExtractedElementData,
    IPartVersionData,
    AlgoliaSearchDocumentData,
    IVertexMap,
    IVertexObject,
    setDefaultRoleData,
    SchematicPosition2,
    IVector2,
    TypesenseSearchDocumentData,
} from "@buildwithflux/models";
import {normalizeRadianOrientation} from "@buildwithflux/shared";
import {applyChange} from "deep-diff";
import {produce} from "immer";
import {cloneDeep} from "lodash";
import {Vector2} from "three";
import Urlbox from "urlbox";

import ModuleIcon from "../../../resources/assets/module-icon.svg";
import ProjectIcon from "../../../resources/assets/project_icon.svg";
import {ANON_ROLE_UID} from "../../../resources/constants/anonRoleUid";
import {DIAGRAM_ROOT_ELEMENT, PCB_ROOT_ELEMENT} from "../../../resources/constants/htmlElementNames";
import {IUserCanvasPresenceData} from "../../coms_engine/BroadcastChannelMessages";
import {ISegmentData} from "../../schematic_editor/scene_subjects/types";
import {VertexUpdate} from "../../schematic_editor/wiring/Wiring";
import {setRole} from "../common/DocumentHelpers";
import {FileStorage, ImageSizes} from "../FileStorage.types";

export class DocumentStorageHelper {
    constructor(private readonly fileStorage: FileStorage) {}

    public static getUniqueHeadPartUidsUsedInDocument(documentElements: IElementsMap) {
        const elements = Object.values(documentElements);

        const partVersions = elements
            .filter((element) => !!element.part_version)
            .map((element) => element.part_version_data_cache);

        return this.filterUniquePartVersions(partVersions).map((partVersion) => ({
            partUid: partVersion.part_uid,
            latestVersion: partVersion.version,
        }));
    }

    public static getUniquePartVersions(elements: IElementData[]) {
        const partVersions = elements.map((element) => element.part_version_data_cache);

        return this.filterUniquePartVersions(partVersions);
    }

    public static sortRecentActiveUsers(userPresences: IUserPresenceData[]): IUserPresenceData[] {
        return userPresences
            .filter((userPresence: IUserPresenceData) => {
                return userPresence.status === UserPresenceStatus.engaging.toString();
            })
            .sort((a, b) => (a.last_seen < b.last_seen ? 1 : -1));
    }

    public static setPcbLayoutRuleOrder(pcbLayoutRule: PcbRule, existingRulesCount: number) {
        return produce(pcbLayoutRule, (draft) => {
            if (draft.order === undefined) {
                draft.order = existingRulesCount + 1;
            }
        });
    }

    // QUESTION: is this still needed? it is not called outside of test code
    public static setPresence(user: IUserData, documentData: IDocumentData, status: UserPresenceStatus) {
        const existingUserPresence = documentData.active_users[user.uid];
        if (existingUserPresence) {
            existingUserPresence.user_handle = user.handle;
            existingUserPresence.full_name = user.full_name;
            existingUserPresence.last_seen = new Date().getTime();
            existingUserPresence.status = status.toString();

            if (!existingUserPresence.color) {
                existingUserPresence.color = this.getUserColor(Object.values(documentData.active_users));
            }
        } else {
            documentData.active_users[user.uid] = {
                user_uid: user.uid,
                user_handle: user.handle,
                full_name: user.full_name,
                last_seen: new Date().getTime(),
                status: status.toString(),
                color: this.getUserColor(Object.values(documentData.active_users)),
            } as IUserPresenceData;
        }
    }

    public static isElementData(object: any): object is IElementData {
        return "part_version_data_cache" in object;
    }

    public static isNetData(object: any): object is IDocumentNet {
        return "pins" in object;
    }

    public static isSegmentData(object: any): object is ISegmentData {
        return "startVertex" in object && "endVertex" in object;
    }

    public static isRouteData(object: any): object is IRouteData {
        return "endpoints" in object;
    }

    public static isUserMousePositionData(object: any): object is IUserCanvasPresenceData {
        return "user_handle" in object;
    }

    public static isSchematicCommentThread(object: any): object is ICommentThreadData {
        return this.isCommentThread(object) && object.anchor.type === CommentThreadAnchorType.position;
    }

    public static isCommentThread(object: any): object is ICommentThreadData {
        return "anchor" in object;
    }

    public static applyVertexUpdates(
        data: {
            vertices: IVertexMap;
            elements: IElementsMap;
            routes: IRoutesMap;
        },
        updates: VertexUpdate[],
    ) {
        updates.forEach((update) => {
            applyChange(data.vertices, true, update);
            if (
                update.kind === "E" &&
                update.path &&
                update.path[update.path.length - 1] === "terminal" &&
                !!update.lhs &&
                update.rhs === null
            ) {
                const connectedElementId = (update.lhs as any).element_uid;
                const connectedElement = data.elements[connectedElementId];
                // if a vertex has had its branchpoint removed. remove associated element
                if (connectedElement && connectedElement.part_uid === ROUTE_BRANCH_POINT_PART_UID) {
                    delete data.elements[connectedElementId];
                }
            }
            if (update.path?.includes("position")) {
                const updatedVertexPosition: IVertexObject = data.vertices[update.path[0]]!;
                if (
                    updatedVertexPosition.terminal &&
                    updatedVertexPosition.terminal.terminal_uid === ROUTE_BRANCH_POINT_TERMINAL_UID &&
                    data.elements[updatedVertexPosition.terminal.element_uid]!
                ) {
                    data.elements[updatedVertexPosition.terminal.element_uid]!.diagram_position = new Vector2(
                        updatedVertexPosition.position.x,
                        updatedVertexPosition.position.y,
                    );
                }
            }
            if (update.kind === "D" && !!update.lhs.terminal) {
                // if a vertex with a terminal is removed. remove associated branch point element
                const connectedElementId = (update.lhs.terminal as any).element_uid;
                const connectedElement = data.elements[connectedElementId];
                if (connectedElement && connectedElement.part_uid === ROUTE_BRANCH_POINT_PART_UID) {
                    delete data.elements[connectedElementId];
                }
            }
        });
    }

    /**
     * @deprecated see DocumentStorageHelper.flattenElements
     */
    public static getAllElementsRecursively(
        elements: IElementData[] | {[uid: string]: IElementData | IExtractedElementData},
    ) {
        elements = Array.isArray(elements) ? Object.fromEntries(elements.map((elm) => [elm.uid, elm])) : elements;
        let allElements: (IElementData | IExtractedElementData)[] = [];
        for (const [_uid, element] of Object.entries(elements)) {
            const subElements = DocumentStorageHelper.getAllElementsRecursively(
                element.part_version_data_cache?.extracted_element_cache ?? {},
            );
            allElements = allElements.concat(subElements);
            allElements.push(element);
        }
        return allElements;
    }

    public static getNonTerminalElements(documentElements: IElementsMap) {
        const elements: IElementData[] = [];
        Object.values(documentElements).forEach((element) => {
            const property = ElementHelper.getElementPartCategoryProperty(element);
            if (!property || property?.value !== partPropertyCategories.terminal) {
                elements.push(element);
            }
        });
        return elements;
    }

    public static mapSegmentIdToVertexIds(segmentId: string) {
        return segmentId.split("__");
    }
    public static mapVerticesToSegmentId(startVertexId: string, endVertexId: string) {
        return startVertexId.concat("__").concat(endVertexId);
    }

    public static getSelectedVerticesData(documentRouteVertices: IVertexMap | undefined, objectUids: string[]) {
        const selectedVerticesData: IVertexMap = {};

        objectUids.forEach((selectedSubjectUid: string) => {
            if (!documentRouteVertices) {
                return;
            }

            const [startVertexId, endVertexId] = DocumentStorageHelper.mapSegmentIdToVertexIds(selectedSubjectUid);
            const startVertex = documentRouteVertices[startVertexId!]!;
            const endVertex = documentRouteVertices[endVertexId!]!;

            if (startVertex) {
                selectedVerticesData[startVertexId!] = startVertex;
            }
            if (endVertex) {
                selectedVerticesData[endVertexId!] = endVertex;
            }
        });
        return Object.values(selectedVerticesData);
    }

    public static getSelectedRoutesData(documentRoutes: IRoutesMap, subjectsUids: string[]) {
        return subjectsUids.map((uId) => documentRoutes[uId]!).filter((routeData) => routeData);
    }

    public static getSelectedCommentThreadsData(documentCommentThreads: ICommentThreadsMap, subjectsUids: string[]) {
        return subjectsUids.map((uId) => documentCommentThreads[uId]!).filter((commentThread) => commentThread);
    }

    public static addElement(documentData: IDocumentData, element: IElementData) {
        documentData.elements[element.uid] = element;
    }

    public static addRoute(documentData: IDocumentData, route: IRouteData) {
        documentData.routes[route.uid] = route;
    }

    public static deleteElements(documentData: IDocumentData, elements: IElementData[]) {
        elements.forEach((elementToDelete: IElementData) => {
            const routesToDelete: Set<IRouteData> = new Set();
            // TODO@Chris switch this to Terminals
            Object.values(documentData.routes).forEach((route: IRouteData) => {
                if (
                    route.endpoints.start_element_terminal.element_uid === elementToDelete.uid ||
                    route.endpoints.end_element_terminal.element_uid === elementToDelete.uid
                ) {
                    routesToDelete.add(route);
                }
            });
            this.deleteRoutes(documentData, Array.from(routesToDelete));
            delete documentData.elements[elementToDelete.uid];
        });
    }

    public static deleteRoutes(documentData: IDocumentData, routes: IRouteData[]) {
        routes.forEach((routeToDelete: IRouteData) => {
            delete documentData.routes[routeToDelete.uid];
        });
    }

    // TODO: refactor so no cloneDeep needed
    public static getOrSetAnonRole(documentData: IDocumentData): DocumentPermissionsData {
        if (!documentData.roles || Object.keys(documentData.roles).length === 0) {
            // NOTE: cloneDeep on an entire document can take 30ms or more!
            const clonedDocumentData = cloneDeep(documentData);
            setDefaultRoleData(clonedDocumentData);
            return clonedDocumentData.roles[ANON_ROLE_UID]!;
        }
        let anonRole = documentData.roles[ANON_ROLE_UID];
        if (!anonRole) {
            anonRole = createDefaultAnonUserPermissions();
            setRole(documentData, anonRole);
        }
        return anonRole;
    }

    public static isDocumentAComponent(
        documentData: AlgoliaSearchDocumentData | IBaseDocumentData | TypesenseSearchDocumentData,
    ) {
        return documentIsPublished(documentData);
    }

    public static getUserColor(activeUsers: IUserPresenceData[]): string {
        const colorsInUse = activeUsers.map((activeUser) => activeUser.color);
        const predefinedColors = colors.userPresence.userColors.map((userColor) => userColor.color);
        const availableColors = predefinedColors.filter((color) => !colorsInUse.includes(color));
        return availableColors[0] ?? this.getRandomColor();
    }

    public static getAbsolutePreviewUrl(
        documentUid: string,
        documentSlug: string,
        documentOwnerHandle: string,
        documentEnterpriseOwnerUid: EnterpriseUid | undefined,
        documentEnterpriseOwnerHandle: EnterpriseHandle | undefined,
        documentOrganizationOwnerIsEnterpriseDefault: boolean | undefined,
        documentLocalToDocumentUid: string | undefined,
        editorMode?: EditorModes,
        cameraMode?: CameraMode,
    ) {
        const documentUrl = getAbsoluteDocumentUrl(
            {
                uid: documentUid,
                slug: documentSlug,
                owner_handle: documentOwnerHandle,
                enterprise_owner_uid: documentEnterpriseOwnerUid,
                enterprise_owner_handle: documentEnterpriseOwnerHandle,
                organization_owner_is_enterprise_default: documentOrganizationOwnerIsEnterpriseDefault,
                localToDocumentUid: documentLocalToDocumentUid,
            },
            {urlParams: {editorMode, cameraMode, embed: true}},
        );

        if (editorMode === EditorModes.schematic) {
            return this.getScreenShotOfSchematic(documentUrl);
        } else if (editorMode === EditorModes.pcb) {
            return this.getScreenShotOfPcb(documentUrl);
        }

        // NOTE: returns undefined here! Intentional? Not sure.
    }

    public getPreviewUrl(
        previewAssetName: string | undefined | null,
        isPublished?: boolean | undefined | null,
        size: ImageSizes = ImageSizes.extraMedium,
        format: "image" | "model" = "image",
        packageCode?: string,
    ) {
        if (format === "image") {
            if (previewAssetName) {
                if (previewAssetName.toLowerCase().endsWith(".svg")) {
                    return this.fileStorage.getFileURL(previewAssetName);
                } else {
                    return this.fileStorage.getProcessedImageUrl(previewAssetName, size, "contain");
                }
            } else if (isPublished) {
                return ModuleIcon;
            }
        } else if ((previewAssetName || packageCode) && isPublished) {
            if (
                previewAssetName &&
                (previewAssetName.toLowerCase().endsWith(".step") ||
                    previewAssetName.toLowerCase().endsWith(".stp") ||
                    previewAssetName.toLowerCase().endsWith(".glb") ||
                    previewAssetName.toLowerCase().endsWith(".gltf"))
            ) {
                return getProcessed3dModelUrl(previewAssetName);
            } else if (packageCode && isPublished) {
                return getProcessed3dModelUrl(`assets/pcb/standard_packages/${packageCode}.step`);
            }
        }

        return ProjectIcon;
    }

    public getAssetUrl(
        previewAssetName: string | undefined | null,
        size: ImageSizes = ImageSizes.extraMedium,
        format: "image" | "model" = "image",
    ) {
        if (format === "image") {
            if (previewAssetName) {
                if (previewAssetName.toLowerCase().endsWith(".svg")) {
                    return this.fileStorage.getFileURL(previewAssetName);
                } else {
                    return this.fileStorage.getProcessedImageUrl(previewAssetName, size, "cover");
                }
            }
        } else if (previewAssetName) {
            if (
                previewAssetName.toLowerCase().endsWith(".step") ||
                previewAssetName.toLowerCase().endsWith(".stp") ||
                previewAssetName.toLowerCase().endsWith(".glb") ||
                previewAssetName.toLowerCase().endsWith(".dxf") ||
                previewAssetName.toLowerCase().endsWith(".gltf")
            ) {
                return getProcessed3dModelUrl(previewAssetName);
            }
        }

        return ProjectIcon;
    }

    public static getCenter(positions: IVector2[]): IVector2 {
        const xPositions = positions.map((pos) => pos.x);
        const yPositions = positions.map((pos) => pos.y);

        const minXPosition = Math.min(...xPositions);
        const maxXPosition = Math.max(...xPositions);
        const minYPosition = Math.min(...yPositions);
        const maxYPosition = Math.max(...yPositions);

        const centerX = (minXPosition + maxXPosition) / 2;
        const centerY = (minYPosition + maxYPosition) / 2;

        return {x: centerX, y: centerY};
    }

    public static rotateSchematicPositionAroundPivot(point: SchematicPosition2, pivot: IVector2, radians: number) {
        const rotated = vec2.withOrigin(point, pivot, (v) => v.rotate(radians));
        return {
            ...rotated,
            orientation: normalizeRadianOrientation((point.orientation ?? 0) + radians),
        };
    }

    private static getScreenShotOfSchematic(documentUrl: string) {
        const urlbox = Urlbox(process.env.REACT_APP_URLBOX_KEY);

        const options = {
            url: documentUrl,
            format: "png",
            selector: `.${DIAGRAM_ROOT_ELEMENT}`,
            ttl: 86400 * 1, // 1x 24h
            delay: 1000 * 30, // 60x 1s
            user_agent: "urlbox bot",
        };

        return urlbox.buildUrl(options);
    }

    private static getScreenShotOfPcb(documentUrl: string) {
        const urlbox = Urlbox(process.env.REACT_APP_URLBOX_KEY);

        const options = {
            url: documentUrl,
            format: "png",
            selector: `#${PCB_ROOT_ELEMENT}`,
            ttl: 86400 * 1, // 1x 24h
            delay: 1000 * 60, // 60x 1s
            user_agent: "urlbox bot",
        };

        return urlbox.buildUrl(options);
    }

    private static buildElementDesignatorString(designatorPrefix: string, designatorCount: number) {
        return `${designatorPrefix}${designatorCount}`;
    }

    /**
     * Returns a new designator sequencer based off of input element map. The sequencer allows calling
     * code to obtain a sequentially unique designator (with respect to input element map), that's unique
     * to the designator prefix obtained from the version data of the specified part
     *
     * @param elementMap
     * @returns
     */
    public static createDesignatorSequencer(elementMap: IElementsMap) {
        // Local resuable helper used to ensure reliable string matching
        const homogenizeString = (value: string) => value.trim().toLowerCase();

        // Obtain a flat list of unique designators from the input element map
        const designatorSet = new Set(
            DocumentStorageHelper.getAllElementsRecursively(elementMap)
                .filter((element) => element.label)
                .map((element) => homogenizeString(element.label ?? "")),
        );

        // Define the external API that allows calling code to obtain next
        // ordinal/sequential designator with respect to specified element
        // map
        function findNextDesignatorFromPartVersionData(partVersionData: IPartVersionData) {
            const designatorPrefix = getDesignatorPrefixFromProperties(partVersionData.properties);
            if (!designatorPrefix) {
                return "";
            }

            // Perform linear search to find next unique designator
            for (let count = 1; ; count++) {
                const designator = DocumentStorageHelper.buildElementDesignatorString(designatorPrefix, count);
                if (!designatorSet.has(homogenizeString(designator))) {
                    // A unique designator found, update the local set
                    // of designators and return current unique designator
                    // to caller
                    designatorSet.add(homogenizeString(designator));

                    return designator;
                }
            }
        }

        function findNextDesignatorFromElementData(elementData: IElementData) {
            return findNextDesignatorFromPartVersionData(elementData.part_version_data_cache);
        }

        return {
            findNextDesignatorFromPartVersionData,
            findNextDesignatorFromElementData,
        };
    }

    public static getNextDesignator(elementsData: IElementsMap, partVersionData: IPartVersionData) {
        return DocumentStorageHelper.createDesignatorSequencer(elementsData).findNextDesignatorFromPartVersionData(
            partVersionData,
        );
    }

    private static filterUniquePartVersions(partVersions: IPartVersionData[]) {
        const uniquePartVersions: IPartVersionData[] = [];

        partVersions.forEach((partVersion) => {
            const found = uniquePartVersions.find(
                (uniquePartVersion) => uniquePartVersion.part_uid === partVersion.part_uid,
            );

            if (!found) {
                uniquePartVersions.push(partVersion);
            }
        });

        return uniquePartVersions;
    }

    private static getRandomColor() {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
}
