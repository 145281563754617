import {
    getPaymentPlanCategory,
    isPaidPaymentPlanCategory,
    IUserData,
    IUserPrivateMetadata,
    PaymentPlanCategory,
    PaymentPlanUids,
} from "@buildwithflux/core";
import {AtLeast} from "@buildwithflux/shared";
import {produce} from "immer";
import {isEqual} from "lodash";
import {create, StoreApi, UseBoundStore} from "zustand";

import {useFluxServices} from "../../injection/hooks";
import {CurrentUserService} from "../auth";
import {selectFreeUserMaxPrivateProjects, useFeatureFlags} from "../common/hooks/featureFlags/useFeatureFlag";
import {AnalyticsStorage} from "../storage_engine/AnalyticsStorage";
import {UserStorage} from "../storage_engine/UserStorage";

type UserStoreApi = {
    setPrivateMetaDataLoadingError: () => void;
    setUserDataLoadingError: () => void;
    setRemoteUserData: (userData: IUserData) => void;
    setUserData: (userData: AtLeast<IUserData, "handle">) => void;
    setRemotePrivateMetadata: (privateMetadata: IUserPrivateMetadata) => void;
    setPrivateMetadata: (privateMetadata: Partial<IUserPrivateMetadata>) => Promise<void>;
};

type UserStoreState = UserStoreApi & {
    privateMetaDataLoadingError: boolean | undefined;
    userDataLoadingError: boolean | undefined;
    userData?: IUserData;
    privateMetadata?: IUserPrivateMetadata;
    /**
     * @deprecated Should be replaced by plans and entitlements
     */
    hasActiveSubscription: boolean | undefined;
    currentUserUnsub: () => void;
};

export type UseUserStore = UseBoundStore<StoreApi<UserStoreState>>;

/**
 * @deprecated We need to check more than subscriptionStatus - this whole piece of state should be replaced by plans and
 *             entitlements
 */
function getHasActiveSubscription(privateMetadata: IUserPrivateMetadata | undefined, user: IUserData | undefined) {
    if (user?.activeProductUid === PaymentPlanUids.userLegacyEdu) {
        return true;
    }

    return (
        Object.values(privateMetadata?.payment?.subscriptions || {}).filter(
            (subscription) => subscription.subscriptionStatus === "created",
        ).length > 0
    );
}

export const createUserStoreHook = (
    currentUserService: CurrentUserService,
    analyticsStorage: AnalyticsStorage,
    userStorage: UserStorage,
): UseUserStore =>
    create<UserStoreState>()((set, get) => {
        const currentUserUnsub = currentUserService.subscribeToUserChanges((change) => {
            const {
                userData,
                privateMetadata,
                setRemotePrivateMetadata,
                setRemoteUserData,
                setUserDataLoadingError,
                setPrivateMetaDataLoadingError,
            } = get();

            if (!isEqual(userData, change.user)) {
                if (change.user) setRemoteUserData(change.user);
                else {
                    setUserDataLoadingError();
                }
            }
            if (!isEqual(privateMetadata, change.privateMetadata)) {
                if (change.privateMetadata) setRemotePrivateMetadata(change.privateMetadata);
                else {
                    setPrivateMetaDataLoadingError();
                }
            }
        });

        // The current user service will not send subscribe events for an already-settled user
        // We must grab the initial state ourselves
        const userData = currentUserService.getCurrentUser();
        const privateMetadata = currentUserService.getCurrentUserPrivateMetadata();

        return {
            currentUserUnsub,
            privateMetaDataLoadingError: undefined,
            userDataLoadingError: undefined,
            privateMetadata,
            hasActiveSubscription: getHasActiveSubscription(privateMetadata, userData),
            setPrivateMetaDataLoadingError: () =>
                set(
                    produce((state) => {
                        state.privateMetaDataLoadingError = true;
                    }),
                ),
            setUserDataLoadingError: () =>
                set(
                    produce((state) => {
                        state.userDataLoadingError = true;
                    }),
                ),
            setRemotePrivateMetadata: (newPrivateMetadata) =>
                set(
                    produce((state) => {
                        state.privateMetadata = newPrivateMetadata;
                        state.privateMetaDataLoadingError = false;
                        state.hasActiveSubscription = getHasActiveSubscription(newPrivateMetadata, state.userData);
                    }),
                ),
            setPrivateMetadata: async (newPrivateMetadata) => {
                let updatedPrivateMetadata;

                set((state) => {
                    if (!state.userData) {
                        throw Error("Requires userData to be set");
                    }

                    state.privateMetadata = {
                        ...state.privateMetadata,
                        ...newPrivateMetadata,
                        uid: state.userData.uid,
                    };

                    state.privateMetaDataLoadingError = false;

                    updatedPrivateMetadata = {
                        ...newPrivateMetadata,
                        uid: state.userData.uid,
                    };

                    state.hasActiveSubscription = getHasActiveSubscription(state.privateMetadata, state.userData);

                    analyticsStorage.setUser(state.userData);

                    return state;
                });

                if (updatedPrivateMetadata) {
                    await userStorage.setPrivateUserMetadata(updatedPrivateMetadata);
                }
            },
            userData,
            setRemoteUserData: (userData) =>
                set(
                    produce((state) => {
                        state.userData = userData;
                        state.userDataLoadingError = false;
                        state.hasActiveSubscription = getHasActiveSubscription(state.privateMetadata, state.userData);
                    }),
                ),
            setUserData: async (userData) => {
                set(
                    produce((state) => {
                        if (!state.userData) {
                            throw Error("Requires userData to be set");
                        }

                        state.userDataLoadingError = false;

                        state.userData = {
                            ...state.userData,
                            ...userData,
                            uid: state.userData.uid,
                        };

                        state.hasActiveSubscription = getHasActiveSubscription(state.privateMetadata, state.userData);

                        analyticsStorage.setUser(state.userData);
                    }),
                );

                await userStorage.mergeUser(userData);
            },
        };
    });

/**
 * Gets a reactive slice of the user store: the private metadata for the current user
 *
 * This is reactive in the sense that if you call this hook from a component, it'll re-execute if the value
 * of that piece of state changes (similar to if you called a useState setter)
 */
export function useUserPrivateMetadata() {
    return useFluxServices().useUserStore((state) => state.privateMetadata);
}

/**
 * @deprecated Should be using plans and entitlements services
 */
export function useUserHasActiveSubscription(): boolean | undefined {
    return useFluxServices().useUserStore((state) =>
        !state.privateMetaDataLoadingError ? state.hasActiveSubscription : undefined,
    );
}

/**
 * @deprecated Should be using plans and entitlements services
 */
export function useUserHasEduActiveSubscription(): boolean {
    return useUserPaymentPlanCategory() === PaymentPlanCategory.enum.userLegacyEdu;
}

/**
 * @deprecated Should be using plans and entitlements services
 */
export function useUserHasPaidActiveSubscription(): boolean {
    const category = useUserPaymentPlanCategory();
    const active = useUserHasActiveSubscription();
    return !!category && !!active && isPaidPaymentPlanCategory(category);
}

/**
 * @deprecated Should be using plans and entitlements services
 */
export function useUserPaymentPlanCategory(): PaymentPlanCategory | undefined {
    return useFluxServices().useUserStore((state) =>
        state.userData ? getPaymentPlanCategory(state.userData) : undefined,
    );
}

export type OnboardingState = "incomplete" | "complete" | "unknown";

/**
 * State hook for the user's onboarding state.
 * Returns "complete(' if the user has completed onboarding, "incomplete" if they have not, and "unknown" if we don't know yet
 */
export function useUserOnboardingState(): OnboardingState {
    return useFluxServices().useUserStore((state) => {
        if (state.privateMetaDataLoadingError || state.privateMetadata === undefined) {
            return "unknown";
        }

        if (state.privateMetadata.onboarding?.onboarded) {
            return "complete";
        } else {
            return "incomplete";
        }
    });
}

export function useUserPrivateProjectCount() {
    return useFluxServices().useUserStore((state) => state.privateMetadata?.projects?.privateProjectCount || 0);
}

/**
 * @deprecated Should be using plans and entitlements services
 */
export function useUserExceedsAllowedPrivateProjects() {
    const freeUserMaxPrivateProjects = useFeatureFlags(selectFreeUserMaxPrivateProjects);

    return useFluxServices().useUserStore((state) => {
        // some users don't have a private meta data object yet...we default them to allowing more private projects
        if (state.privateMetaDataLoadingError) {
            return false;
        }

        if (!state.privateMetadata) {
            return;
        }

        if (state.hasActiveSubscription) {
            return false;
        }

        if (!freeUserMaxPrivateProjects) {
            return;
        }

        return (state.privateMetadata?.projects?.privateProjectCount || 0) >= freeUserMaxPrivateProjects;
    });
}

/**
 * Gets API methods for the user store
 */
export function useUserStoreApi(): UserStoreApi {
    return useFluxServices().useUserStore(
        ({
            setPrivateMetaDataLoadingError,
            setUserDataLoadingError,
            setRemoteUserData,
            setUserData,
            setRemotePrivateMetadata,
            setPrivateMetadata,
        }) => ({
            setPrivateMetaDataLoadingError,
            setUserDataLoadingError,
            setRemoteUserData,
            setUserData,
            setRemotePrivateMetadata,
            setPrivateMetadata,
        }),
    );
}
